
import { defineComponent, reactive } from "vue";
import {
  sk,
  tahapanBelajar,
  tryoutPemantapan,
  topDMember,
} from "./dashboard-member";
import { api } from "@/utils/api-config";
import LineChart from "@/components/line-chart/Main.vue";

export default defineComponent({
  components: {
    LineChart,
  },
  setup() {
    const role = api.getRole();
    // console.log("login-data ", $ck.getCookie('login-data'));

    const listDM = reactive({
      titlePage:
        role === "member" ? "Dashboard Member" : "Dashboard Fitur COID",
      listTMember: [],
      listSK: [],
      listTB: [],
      dataLineChart: {} as any,
      jenisTryout: "",
      listJT: [] as any,
    });

    // for get data widget top
    const getWT = async () => {
      cash("#loadingWTopMember").show();

      const hide = () => cash("#loadingWTopMember").hide();
      const url = await api.getConfigFeatureMember(
        "memberWidgetTop",
        "featureWidgetTop"
      );
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;
      listDM.listTMember = rs;
    };

    // for get syarat dan ketentuan garansi
    const getSK = async () => {
      cash("#loadingSK").show();

      const hide = () => cash("#loadingSK").hide();
      const url = await api.getConfigFeatureMember("memberSK", "featureSK");
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;
      listDM.listSK = rs;
    };

    // for get data tahapan belajar
    const getTB = async () => {
      cash("#loadingTB").show();

      const hide = () => cash("#loadingTB").hide();
      const url = await api.getConfigFeatureMember("memberTB", "featureTB");
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;
      listDM.listTB = rs;
    };

    // for get data line-bar-chart
    const getLBC = async () => {
      cash("#loadingTB").show();

      const hide = () => cash("#loadingTB").hide();
      const url = api.memberTryoutChart + listDM.jenisTryout;
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;
      listDM.dataLineChart = rs;
    };

    const getFilterJT = async () => {
      cash("#loadingTB").show();

      const rs = await api.getJenisTryout();

      listDM.listJT = rs;
      listDM.jenisTryout = rs[0].kode ? rs[0].kode : "skd";

      cash("#loadingTB").hide();
    };

    const getData = async () => {
      await getWT();
      await getSK();
      await getTB();

      if (role === "member") {
        await getFilterJT();
        await getLBC();
      }
    };
    getData();

    const changeTS = async (v: any, f: any) => {
      const val = v.detail.args[1].key;
      const ex: any = listDM;
      ex[f] = val;

      await getLBC();
    };

    return {
      role,
      listDM,
      getData,
      changeTS,
    };
  },
  data() {
    return {
      listSK: sk,
      listTB: tahapanBelajar,
      listTP: tryoutPemantapan,
      listTMember: topDMember,
    };
  },
});
